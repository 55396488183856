import React, {useState, useRef, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Datepicker from "react-datepicker";
import { Textarea } from "@material-tailwind/react";

import "react-datepicker/dist/react-datepicker.css";

export default function MissingForm(props) {
  const { t, i18n } = useTranslation();

  const [auth, setAuth] = useState(false);
  const [pw, setPW] = useState("");

  const petInfo = props.data;
  const status = petInfo.statusMissing;

  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const inThePast = (date) => {
    return date < new Date();
  };

  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
   componentRestrictions: { country: [ "pt", "es", "fr" ] },
   fields: ["address_components", "geometry", "icon", "name"],
   types: ["address"]
  };

  useEffect(() => {
   autoCompleteRef.current = new window.google.maps.places.Autocomplete(
    inputRef.current,
    options
   );
  }, []);

  const petId = useParams();
  const tagId = petId.petId;

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    await fetch( `${process.env.REACT_APP_BACKEND}/${petId.petId}/missing`, {
        method: "POST",
        body: JSON.stringify({ tagId: tagId, lastDate: startDate, lastLocation: location, message: message, solved: false, reminders: true }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(() => {
      window.location.replace( `${process.env.REACT_APP_FRONTEND}/${petId.petId}`);
    })
  }

  const validateUser = async (e) => {
    e.preventDefault();
    let res = await fetch( `${process.env.REACT_APP_BACKEND}/${petId.petId}/edit/validate`, {
        method: "POST",
        body: JSON.stringify({petId, pw}),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then((res) => {
        if (res.status === 200) {
          //setEdit(true);
          setAuth(true);
        } else {
          alert(t('alerts.wrongPass'));
        }
      })
  }


  while (!auth) {
    return(
        <div className='grid place-items-center'>
          <div className="top-1/4 w-[350px] h-auto justify-center p-4 bg-alert-bg border-alert-ac border-2 rounded-[10px] shadow-xl">
            <form className="space-y-8 mt-8">
              <div>
                <p className='font-bold text-lg text-alert-ac'>{t('popup.confirmAlert')}</p>
                <p className='font-regular mt-2 text-md text-alert-ac'>{t('popup.confirmPassAlert')}</p>
              </div>
              <div>
                <input type="password" id="pw" value={pw} onChange={(e) => setPW(e.target.value)} className="form-input border-alert-ac border-0.5 w-full h-[50px] rounded-lg bg-alert-bg text-alert-ac text-xl" required/>
              </div>
              <button className='bg-alert-ac rounded-[10px] h-[50px] w-full text-center align-middle' type="submit" onClick={validateUser} >
                <a className='text-alert-bg text-lg font-medium'> 
                  {t('popup.confirm')}
                </a>
                </button>
            </form>
          </div>
        </div>
      );
  }

  return (
      <form className="flex flex-col w-[300px] mx-auto space-y-5 mt-7" action="">
        <div className='grid grid-cols-1 divide-y-2 divide-alert-ac divide-solid'>
          <div>
            <p className='font-bold text-xl text-alert-ac'>{t('missing.firstTitle')}</p>
          </div>
          <div>
            <label htmlFor="date" className='font-medium text-l text-alert-ac'>{t('missing.date')}</label>
            <Datepicker selected={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => setStartDate(date)} filterDate={inThePast} className="form-input w-full h-10 rounded-lg border-alert-ac border-2 bg-alert-bg text-alert-ac" required/>
            
            <label htmlFor="place" className='font-medium text-l text-alert-ac'>{t('missing.place')}</label>
            <input type="text" ref={inputRef} placeholder="" id="place" value={location} onChange={(e) => setLocation(e.target.value)} className="form-input w-full h-10 rounded-lg border-alert-ac border-2 bg-alert-bg text-alert-ac" required/>
          </div>
        </div>
        <div className='grid grid-cols-1 divide-y-2 divide-alert-ac divide-solid'>
          <div>
            <p className='font-bold text-xl text-alert-ac'p>{t('missing.secondTitle')}</p>
          </div>
          <div>
            <label htmlFor="message" className='font-medium text-l text-alert-ac'>{t('missing.message')}</label>
            <Textarea rows="4" id="message" value={message} onChange={(e) => setMessage(e.target.value)} className="form-input border-0 p-2 w-full rounded-lg border-alert-ac bg-alert-bg text-alert-ac"/>
          </div>
        </div>
        <div className="grid grid-rows-4 gap-7 place-items-center mt-4">           
          <button type="submit"  onClick={handleOnSubmit} className='bg-alert-ac rounded-[10px] h-[50px] w-[300px] shadow-lg text-center align-middle'>
            <a className='text-alert-bg text-lg font-medium'>
              {t('form.button')}
            </a>
          </button>
        </div>
      </form>
  );
}