import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Banner from '../components/banner'
import Search from '../components/search'

export default function Start() {
  const [ data, setData ] = useState({});
  const petId = useParams();

  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_BACKEND}/${petId.petId}`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //     .then(res => res.json() )
  //     .then(data => {
  //       setData(data.data[0]);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     })
  // }, []);


  // const status = data.statusMissing;
  // const condition = data.statusPaw;

  return (
    <div className = 'bg-regular-ac/75  flex flex-col min-h-screen w-full mx-auto justify-between'>
        <header><Banner data = {''}/></header>
        <main className='flex-grow'>
          <Search/>
        </main>
    </div>
  );
}