import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AlertTitle() {
  const { t, i18n } = useTranslation();

  return (
      <div className="flex flex-col w-[300px] mx-auto">
        <div className='font-extrabold text-4xl text-alert-ac'>
          <p>{t('missingTitle')}</p>
        </div>
      </div>
  );
}