import React, {useState, useRef, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function ReunitedQ() {
  const { t, i18n } = useTranslation();

  const [auth, setAuth] = useState(false);
  const [pw, setPW] = useState("");

  const petId = useParams();
  const tagId = petId.petId;

  const validateUser = async (e) => {
    e.preventDefault();
    let res = await fetch( `${process.env.REACT_APP_BACKEND}/${petId.petId}/edit/validate`, {
        method: "POST",
        body: JSON.stringify({petId, pw}),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then((res) => {
        if (res.status === 200) {
          //setEdit(true);
          setAuth(true);
        } else {
          alert(t('alerts.wrongPass'));
        }
      })
  }

  const updateMissing = async (e) => {
    e.preventDefault();
    let res = await fetch( `${process.env.REACT_APP_BACKEND}/${petId.petId}/update`, {
        method: "POST",
        body: JSON.stringify({tagId}),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(() => {
      window.location.replace( `${process.env.REACT_APP_FRONTEND}/${petId.petId}`);
    })
  }
  

  while (!auth) {
    return(
        <div className='grid place-items-center'>
          <div className="top-1/4 w-[350px] h-auto justify-center p-4 bg-alert-bg border-alert-ac border-2 rounded-[10px] shadow-xl">
            <form className="space-y-8 mt-8">
              <div>
                <p className='font-bold text-lg text-alert-ac'>{t('popup.removeAlert')}</p>
                <p className='font-regular mt-2 text-md text-alert-ac'>{t('popup.removePassAlert')}</p>
              </div>
              <div>
                <input type="password" id="pw" value={pw} onChange={(e) => setPW(e.target.value)} className="form-input border-alert-ac border-0.5 w-full h-[50px] rounded-lg bg-alert-bg text-alert-ac text-xl" required/>
              </div>
              <button className='bg-alert-ac rounded-[10px] h-[50px] w-full text-center align-middle' type="submit" onClick={validateUser} >
                <a className='text-alert-bg text-lg font-medium'> 
                  {t('popup.confirm')}
                </a>
                </button>
            </form>
          </div>
        </div>
      );
  }

  return (
      <form className="flex flex-col w-[300px] mx-auto space-y-5 mt-7" action="">
        <div>
          <p className='font-bold text-xl text-alert-ac'>{t('reunited.firstTitle')}</p>
        </div>
        <div className="grid grid-cols-2 space-x-2 mt-2">          
          <div>
            <button type="submit"  onClick={updateMissing} className={'border-3 border-alert-ac rounded-[10px] h-[50px] w-full shadow-lg text-center align-middle'}>
              <a className='text-alert-ac text-xl font-bold'>
                {t('reunited.y')}
              </a>
            </button>
          </div> 
          <div>
            <button type="submit"  href={`/${petId.petId}`} className={'border-3 border-alert-ac rounded-[10px] h-[50px] w-full shadow-lg text-center align-middle'}>
              <a className='text-alert-ac text-xl font-bold'>
                {t('reunited.n')}
              </a>
            </button>
          </div> 
        </div>   
      </form>
  );
}