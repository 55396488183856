import React from 'react';

export default function Loading() {
  return (
    <div className='h-screen w-screen bg-regular-bg'>
      <div className='py-[50vh] px-[50vw]'>
        <div className="text-regular-ac h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
        </div>
      </div>
    </div>
  );
}