import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Banner from '../components/banner'
import Search from '../components/search'

export default function Home() {
  const { t, i18n } = useTranslation();

  window.location.replace('https://store.pawdiguard.com');

  return (
    <div className='h-screen w-screen bg-regular-bg'>
    </div>
  );
}