import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


import Banner from '../components/banner'
import AlertTitle from '../components/alertTitle'
import Footer from '../components/footer'
import ReunitedQ from '../components/reunitedQ';

export default function Reunited() {

  const [ data, setData ] = useState({});
  const petId = useParams();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND}/${petId.petId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json() )
      .then(data => {
        setData(data.data[0]);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  const status = data.statusMissing;
  const condition = data.statusPaw;

  return (
    <div className = 'bg-alert-bg flex flex-col min-h-screen w-full mx-auto justify-between'>
        <header><Banner data = {data}/></header>
        <main className='flex-grow'>
          <AlertTitle/>
          <ReunitedQ/>
        </main>
        <footer className='h-12 py-4'><Footer data = {data}/></footer>
    </div>
  );
}