import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { sendEmail } from '../Utils.js';

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

//import 'react-phone-number-input/style.css'
//import PhoneInput from 'react-phone-number-input'


export default function Form() {
  const { t, i18n } = useTranslation();
  const current = i18n.languages[0];

  const [auth, setAuth] = useState(false);
  // let confirmation = false;
  const edit = window.location.href.includes('edit');
  const [pw, setPW] = useState("");

  const petId = useParams();

  const [petName, setPetName] = useState();
  const [petGender, setPetGender] = useState();
  const [petSter, setPetSter] = useState();
  const [microchip, setMicrochip] = useState();
  const [ownerEmail, setOwnerEmail] = useState();
  const [ownerEmailConf, setOwnerEmailConf] = useState("");
  const [ownerPhone1, setOwnerPhone1] = useState();
  //const [ownerPhone2, setOwnerPhone2] = useState("");
  const [vetName, setVetName] = useState("");
  const [vetPhone, setVetPhone] = useState("");
  const [terms, setTerms] = useState(false);
  const [ownerPw, setOwnerPw] = useState("");
  const [ownerPwConf, setOwnerPwConf] = useState("");
  const statusPaw = 'used';
  const statusMissing = false;

  //A editar
  const ownerPhone2 = "";

  let password = Math.random().toString(36).slice(-8);
  let missingUrl = `${process.env.REACT_APP_FRONTEND}/${petId.petId}/missing`;

  let message = '';
  let subject = '';

  const emailDef = () => {
    if ( current === 'en' ) {
      message = `Congratulations!\n${petName} has now a functioning Pawdiguard! Save this e-mail since it includes important information that you might need in the future.
      \nTo edit your data use the password: ${ownerPw}
      \nIf ${petName} unfortunately goes missing, report ${petName}'s disappearance by accessing: ${missingUrl}`;  
      
      subject = 'PAWDIGUARD - Registry successful';
    } else {
      message = `Parabéns!\nO/A ${petName} já tem uma Pawdiguard funcional! Guarda este e-mail uma vez que contém informação importante que pode ser necessária no futuro.
      \nPara editares os dados usa a password: ${ownerPw}
      \nSe o/a ${petName}, infelizmente, estiver desaparecido/a, reporta o seu desaparecimento ao aceder aqui: ${missingUrl}`;  
      
      subject = 'PAWDIGUARD - Registo concluído';
    }
  }

  const isFormValid = () => {
    if (!petName.trim()) {
      return false;
    }

    if (!petGender.trim()) {
      return false;
    }

    if (!petSter.trim()) {
      return false;
    }

    if (!microchip.trim()) {
      return false;
    }

    if (!ownerEmail.trim()) {
      return false;
    }

    if (!ownerPhone1.trim()) {
      return false;
    }

    if (ownerEmail !== ownerEmailConf) {
      alert(t('alerts.wrongEmail'));
      return false;
    }

    if (ownerPw !== ownerPwConf) {
      alert(t('alerts.wrongEmail'));
      return false;
    }

    if (!terms && !edit) {
      return false;
    }

    return true;
  }

  const handleOnSubmit = async (e) => {
    if (isFormValid()) {
      e.preventDefault();
      await fetch( `${process.env.REACT_APP_BACKEND}/${petId.petId}/registed`, {
          method: "POST",
          body: JSON.stringify({"0":{pet: { petName, petGender, microchip, petSter }, owner: { ownerEmail, ownerPhone1, ownerPhone2 }, vet: { vetName, vetPhone }, statusPaw, statusMissing}, "1": { petId, ownerEmail, ownerPw}, "2":{edit}}),
          headers: {
              'Content-Type': 'application/json'
          }
      })
      .then(async () => {
        // if (!edit) {
        await emailDef();
        const email = {
          subj: subject,
          receiver: ownerEmail,
          mess: message
        }
        sendEmail(email);
        // }
        await window.location.replace( `${process.env.REACT_APP_FRONTEND}/${petId.petId}`);
      });
    } else {
      e.preventDefault();
      alert(t('alerts.fillField'));
    }
  }

  const validateUser = async (e) => {
    e.preventDefault();
    await fetch(`${process.env.REACT_APP_BACKEND}/${petId.petId}/edit/validate`, {
        method: "POST",
        body: JSON.stringify({petId, pw}),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then((res) => {
        if (res.status === 200) {
          //setEdit(true);
          setAuth(true);
        } else {
          alert(t('alerts.wrongPass'));
        }
      })
    
  }

  while (!auth & edit) {
    return(
        <div className='grid place-items-center'>
          <div className="top-1/4 w-[350px] h-auto justify-center p-4 bg-regular-bg border-regular-ac border-2 rounded-[10px] shadow-xl">
            <form className="space-y-8 mt-8">
              <div>
                <p className='font-bold text-lg text-regular-ac'>{t('popup.confirmPass')}</p>
                <p className='font-regular mt-2 text-md text-regular-ac'>{t('popup.confirmPassExp')}</p>
              </div>
              <div>
                <input type="password" id="pw" value={pw} onChange={(e) => setPW(e.target.value)} className="form-input border-regular-ac border-0.5 w-full h-[50px] rounded-lg bg-regular-bg text-regular-ac text-xl" required/>
              </div>
              <button className='bg-regular-ac rounded-[10px] h-[50px] w-full text-center align-middle' type="submit" onClick={validateUser} >
                <a href="/#" className='text-regular-bg text-lg font-medium'> 
                  {t('popup.confirm')}
                </a>
                </button>
            </form>
          </div>
        </div>
      );
  }
  if (!edit) {
    return (
          <form className="flex flex-col w-[300px] mx-auto space-y-5 mt-7">
            <div className='grid grid-cols-1 divide-y-2 divide-regular-ac divide-solid'>
              <div>
                <p className='font-bold text-xl text-regular-ac'>{t('form.firstTitle')}</p>
              </div>
              <div>
                <label htmlFor="pet_name" className='font-medium text-l text-regular-ac'>{t('form.name')}</label>
                <input type="text" id="pet_name" value={petName} onChange={(e) => setPetName(e.target.value)} className="form-input peer w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" required/>
                <div className='grid grid-cols-3 space-x-1 mt-2'>
                  <div>
                    <label htmlFor="pet_gender" className='font-medium text-l text-regular-ac'>{t('form.gender')}</label>
                    <select id="pet_gender" value={petGender} onChange={(e) => setPetGender(e.target.value)} className="form-select w-full h-10 rounded-lg text-xs border-regular-ac border-2 bg-regular-bg text-regular-ac" required>
                      <option value="" ></option>
                      <option value="male">{t('form.male')}</option>
                      <option value="female">{t('form.female')}</option>
                    </select>
                  </div>
                  <div>
                  <label htmlFor="pet_microchip" className='font-medium text-l text-regular-ac'>{t('form.microchip')}</label>
                    <select id="pet_microchip" value={microchip} onChange={(e) => setMicrochip(e.target.value)} className="form-select w-full h-10 rounded-lg text-xs border-regular-ac border-2 bg-regular-bg text-regular-ac">
                      <option value=""></option>
                      <option value="true">{t('form.yes')}</option>
                      <option value="false">{t('form.no')}</option>
                      <option value="other">{t('form.other')}</option>
                    </select>   
                  </div>
                  <div>
                    <label htmlFor="pet_sterilized" className='font-medium text-l text-regular-ac'>{t('form.sterilized')}</label>
                    <select id="pet_sterilized" value={petSter} onChange={(e) => setPetSter(e.target.value)} className="form-select w-full h-10 rounded-lg text-xs border-regular-ac border-2 bg-regular-bg text-regular-ac" required>
                      <option value="" ></option>
                      <option value="true">{t('form.yes')}</option>
                      <option value="false">{t('form.no')}</option>
                      <option value="other">{t('form.other')}</option>
                    </select>
                  </div> 
                </div>      
              </div>
            </div>
            <div className='grid grid-cols-1 divide-y-2 divide-regular-ac divide-solid'>
              <div>
                <p className='font-bold text-xl text-regular-ac'>{t('form.secondTitle')}</p>
              </div>
              <div>
                <label htmlFor="owner_email" className='font-medium text-l text-regular-ac'>{t('form.email')}</label>
                <input id="owner_email" value={ownerEmail} onChange={(e) => setOwnerEmail(e.target.value)} type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" className="form-input mb-2 w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" required/>
                <label htmlFor="owner_email" className='font-medium text-l text-regular-ac'>{t('form.emailConf')}</label>
                <input id="owner_email2" value={ownerEmailConf} onChange={(e) => setOwnerEmailConf(e.target.value)} type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" className="form-input peer mb-2 w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" required/>
                <label htmlFor="owner_pw" className='font-medium text-l text-regular-ac'>{t('form.password')}</label>
                <input id="owner_pw" value={ownerPw} onChange={(e) => setOwnerPw(e.target.value)} type="password" className="form-input mb-2 w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" required/>
                <label htmlFor="owner_pw_conf" className='font-medium text-l text-regular-ac'>{t('form.pwConf')}</label>
                <input id="owner_pw_conf" value={ownerPwConf} onChange={(e) => setOwnerPwConf(e.target.value)} type="password" className="form-input mb-2 w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" required/>
                <label htmlFor="owner_phone" className='font-medium text-l text-regular-ac'>{t('form.mobile')}</label>
                <div className='space-y-2'>
                  <div >
                  {/* className='number form-input w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac' */}
                    <PhoneInput id="owner_phone" country={"pt"} containerStyle={{border: "2px solid #81559B", borderRadius: "6px", width: "300px", height: "40px"}} inputStyle={{ background: "#E6EBE0", width: "290px", border:"none" }} value={ownerPhone1} onChange={(phone) => setOwnerPhone1(phone)} type="tel" required/>
                  </div>
                  {/* <input id="owner_phone_alt" value={ownerPhone2} onChange={(e) => setOwnerPhone2(e.target.value)} type="tel" className="form-input w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" /> */}
                </div>
              </div>
            </div>
            <div className='grid grid-cols-1 divide-y-2 divide-regular-ac divide-solid'>
              <div>
                <p className='font-bold text-xl text-regular-ac'>{t('form.thirdTitle')}</p>
              </div>
              <div>
                <label htmlFor="vet_name" className='font-medium text-l text-regular-ac'>{t('form.vetName')}</label>
                <input id="vet_name" value={vetName} onChange={(e) => setVetName(e.target.value)} type="text" className="form-input mb-2 w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" />
                <label htmlFor="vet_phone" className='font-medium text-l text-regular-ac'>{t('form.phone')}</label>
                <PhoneInput id="vet_phone" country={"pt"} containerStyle={{border: "2px solid #81559B", borderRadius: "6px", width: "300px", height: "40px"}} inputStyle={{ background: "#E6EBE0", width: "290px", border:"none" }} value={vetPhone} onChange={(phone) => setVetPhone(phone)} type="tel" required/>
                {/* <input id="vet_phone" value={vetPhone} onChange={(e) => setVetPhone(e.target.value)} type="tel" className="form-input w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" /> */}
              </div>
            </div>
            <div className='grid grid-cols-8 space-x-1 mt-2'>
              <div>
                <input id="terms" value={terms} onChange={(e) => setTerms(true)} type="checkbox" className="rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" required/>
              </div>
              <div className='col-span-7'>
                <label htmlFor="terms" className='font-medium text-xs text-regular-ac'>{t('form.agree')} <a href="/tandc" className='underline'>{t('form.tc')}</a></label>
              </div>
            </div>
            <div className="grid grid-rows-4 gap-7 place-items-center mt-4">           
              <button type="submit" onClick={handleOnSubmit} className='bg-regular-ac rounded-[10px] h-[50px] w-[300px] shadow-lg text-center align-middle'>
                <a href="/#" className='text-regular-bg text-lg font-medium'>
                  {t('form.button')}
                </a>
              </button>
            </div>
          </form>
      );
  } else {
    return (
          <form className="flex flex-col w-[300px] mx-auto space-y-5 mt-7" >
            <div className='grid grid-cols-1 divide-y-2 divide-regular-ac divide-solid'>
              <div>
                <p className='font-bold text-xl text-regular-ac'>{t('form.firstTitle')}</p>
              </div>
              <div>
                <label htmlFor="pet_name" className='font-medium text-l text-regular-ac'>{t('form.name')}</label>
                <input type="text" id="pet_name" value={petName} onChange={(e) => setPetName(e.target.value)} className="form-input w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" required/>
                <div className='grid grid-cols-3 space-x-1 mt-2'>
                  <div>
                    <label htmlFor="pet_gender" className='font-medium text-l text-regular-ac'>{t('form.gender')}</label>
                    <select id="pet_gender" value={petGender} onChange={(e) => setPetGender(e.target.value)} className="form-select w-full h-10 rounded-lg text-xs border-regular-ac border-2 bg-regular-bg text-regular-ac" required>
                      <option value="" ></option>
                      <option value="male">{t('form.male')}</option>
                      <option value="female">{t('form.female')}</option>
                    </select>
                  </div>
                  <div>
                  <label htmlFor="pet_microchip" className='font-medium text-l text-regular-ac'>{t('form.microchip')}</label>
                    <select id="pet_microchip" value={microchip} onChange={(e) => setMicrochip(e.target.value)} className="form-select w-full h-10 rounded-lg text-xs border-regular-ac border-2 bg-regular-bg text-regular-ac">
                      <option value="" ></option>
                      <option value="true">{t('form.yes')}</option>
                      <option value="false">{t('form.no')}</option>
                      <option value="other">{t('form.other')}</option>
                    </select>   
                  </div>
                  <div>
                    <label htmlFor="pet_sterilized" className='font-medium text-l text-regular-ac'>{t('form.sterilized')}</label>
                    <select id="pet_sterilizes" value={petSter} onChange={(e) => setPetSter(e.target.value)} className="form-select w-full h-10 rounded-lg text-xs border-regular-ac border-2 bg-regular-bg text-regular-ac" required>
                      <option value="" ></option>
                      <option value="true">{t('form.yes')}</option>
                      <option value="false">{t('form.no')}</option>
                      <option value="other">{t('form.other')}</option>
                    </select>
                  </div> 
                </div>      
              </div>
            </div>
            <div className='grid grid-cols-1 divide-y-2 divide-regular-ac divide-solid'>
              <div>
                <p className='font-bold text-xl text-regular-ac'>{t('form.secondTitle')}</p>
              </div>
              <div>
                <label htmlFor="owner_email" className='font-medium text-l text-regular-ac'>{t('form.email')}</label>
                <input id="owner_email" value={ownerEmail} onChange={(e) => setOwnerEmail(e.target.value)} type="email" className="form-input mb-2 w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" required/>
                <label htmlFor="owner_email" className='font-medium text-l text-regular-ac'>{t('form.emailConf')}</label>
                <input id="owner_email2" value={ownerEmailConf} onChange={(e) => setOwnerEmailConf(e.target.value)} type="email" className="form-input mb-2 w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" required/>
                <label htmlFor="owner_pw" className='font-medium text-l text-regular-ac'>{t('form.password')}</label>
                <input id="owner_pw" value={ownerPw} onChange={(e) => setOwnerPw(e.target.value)} type="password" className="form-input mb-2 w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" required/>
                <label htmlFor="owner_pw_conf" className='font-medium text-l text-regular-ac'>{t('form.pwConf')}</label>
                <input id="owner_pw_conf" value={ownerPwConf} onChange={(e) => setOwnerPwConf(e.target.value)} type="password" className="form-input mb-2 w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" required/>
                <label htmlFor="owner_phone" className='font-medium text-l text-regular-ac'>{t('form.mobile')}</label>
                <div>
                  <PhoneInput id="owner_phone" country={"pt"} containerStyle={{border: "2px solid #81559B", borderRadius: "6px", width: "300px", height: "40px"}} inputStyle={{ background: "#E6EBE0", width: "290px", border:"none" }} value={ownerPhone1} onChange={(phone) => setOwnerPhone1(phone)} type="tel" required/>
                  {/* <input id="owner_phone" value={ownerPhone1} onChange={(e) => setOwnerPhone1(e.target.value)} type="tel" className="form-input w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" required/> */}
                  {/* <input id="owner_phone_alt" value={ownerPhone2} onChange={(e) => setOwnerPhone2(e.target.value)} type="tel" className="form-input w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" /> */}
                </div>
              </div>
            </div>
            <div className='grid grid-cols-1 divide-y-2 divide-regular-ac divide-solid'>
              <div>
                <p className='font-bold text-xl text-regular-ac'>{t('form.thirdTitle')}</p>
              </div>
              <div>
                <label htmlFor="vet_name" className='font-medium text-l text-regular-ac'>{t('form.vetName')}</label>
                <input id="vet_name" value={vetName} onChange={(e) => setVetName(e.target.value)} type="text" className="form-input mb-2 w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" />
                <label htmlFor="vet_phone" className='font-medium text-l text-regular-ac'>{t('form.phone')}</label>
                <PhoneInput id="vet_phone" country={"pt"} containerStyle={{border: "2px solid #81559B", borderRadius: "6px", width: "300px", height: "40px"}} inputStyle={{ background: "#E6EBE0", width: "290px", border:"none" }} value={vetPhone} onChange={(phone) => setVetPhone(phone)} type="tel" required/>
                {/* <input id="vet_phone" value={vetPhone} onChange={(e) => setVetPhone(e.target.value)} type="tel" className="form-input w-full h-10 rounded-lg border-regular-ac border-2 bg-regular-bg text-regular-ac" /> */}
              </div>
            </div>
            <div className="grid grid-rows-4 gap-7 place-items-center mt-4">           
              <button type="submit" onClick={handleOnSubmit} className='bg-regular-ac rounded-[10px] h-[50px] w-[300px] shadow-lg text-center align-middle'>
                <a href="/#" className='text-regular-bg text-lg font-medium'>
                  {t('form.button')}
                </a>
              </button>
            </div>
          </form>
      );
    }
  }
