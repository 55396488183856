import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'pt',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          alerts: {
            successfulData: 'Successful pet registry!',
            locationDenied: `We couldn't retrieve location. Enable your location sharing and try again so we can help this pet get back to its family!`,
            locationGranted: `This location was shared with this pet's owner. Thank you for helping this family!`,
            wrongPass: 'Wrong password',
            fillField: 'Check that all mandatory fields are correct in order to submit',
            matchEmail: `Your e-mails don't match!`,
            matchPass: `Your passwords don't match. Check if there's any mistake.`,
            searchFailed: 'No Pawdiguard was found. Please try later or contact us for help.'
          },
          buttons: {
            call: 'call owners',
            num1: 'contact #1',
            num2: 'contact #2',
            text: 'message owners',
            share: 'share location',
            vet: 'contact vet',
            nearbyVet: 'see nearby vets',
            nearbyShelter: 'see nearby shelters',
          },
          id: {
            microTrue: 'with microchip',
            microFalse: 'without microchip',
            microUnk: 'microchip unknown',
            sterTrue: 'sterelised, ',
            sterFalse: 'not sterelised, ',
            sterUnk: 'unknown if sterelised, '
          },
          form: {
            firstTitle: 'PET',
            secondTitle: 'OWNER',
            thirdTitle: 'VET',
            name: 'name*',
            gender: 'gender*',
            male: 'male',
            female: 'female',
            microchip: 'microchip*',
            sterilized: 'sterilisation*',
            yes: 'yes',
            no: 'no',
            other: `don't know`,
            email: 'e-mail*',
            emailnotedit: `E-mail can't be changed`,
            mobile: 'mobile number*',
            password: 'password*',
            vetName: 'name',
            phone: 'phone number',
            button: 'save info',
            agree: `I agree with the`,
            tc: 'terms and conditions.*',
            emailConf: 'confirm e-mail*',
            pwConf: 'confirm password*',
            validEmail: 'invalid e-mail'
          },
          message: {
            subject: 'PAWDIGUARD - Shared location',
            one: 'Hi\nThe location of your pet was shared with the following coordinates: ',
            two:' with an accuracy of about ',
            three:' meters.\n We hope this message promotes your reunion.\n Pawdiguard'
          },
          missing: {
            firstTitle: 'LAST SEEN',
            secondTitle: 'RELEVANT INFO',
            date: 'when',
            place: 'where',
            message: 'custom message',
            info1: 'Last seen on',
            info2: ' at '
          },
          popup: {
            confirmPass: 'edit data',
            confirmPassExp: 'insert the password you received when your pawdiguard was configured to be able to edit your data',
            confirmEmail: 'Check your e-mail before finalizing the registry process.',
            confirm: 'confirm',
            confirmAlert: 'add disappearance',
            confirmPassAlert: 'insert the password you received when your pawdiguard was configured to be able to add disappearance',
            removeAlert: 'remove disappearance',
            removePassAlert: 'insert the password you received when your pawdiguard was configured to be able to confirm that your pet has been reunited with you',
          },
          reunited: {
            firstTitle: 'HAVE YOU BEEN REUNITED WITH YOUR PET?',
            y: 'yes',
            n: 'no'
          },
          search: {
            vet: 'vet',
            shelter: 'dog shelter'
          },
          editFooter: 'edit data',
          reunitedFooter: `don't send me more reminders`,
          alertFooter: `no longer missing`,
          warning: `THIS PET IS MISSING! HELP IT GET BACK TO IT'S FAMILY!`,
          registryTitle:`MY PET'S INFO`,
          missingTitle:'MY PET IS MISSING',
          searchFor: `Search the code on the back of the Pawdiguard to find more about how you can help.`
        }
      },
      pt: {
        translation: {
          alerts: {
            successfulData: 'Animal registado com sucesso!',
            locationDenied: 'Não conseguimos obter localização. Autoriza a recolha de localização e tenta novamente para que ajudemos este animal a voltar para a sua família!',
            locationGranted: 'Esta localização foi partilhada com o tutor deste animal. Obrigado por ajudares esta família!',
            wrongPass: 'Password errada',
            fillField: 'Verifica que todos os  campos obrigatórios estão preenchidos para submeter a informação',
            wrongEmail: 'E-mail incorrecto. Verifica se tens algum erro.',
            matchPass: 'As passwords não coincidem. Verifica se há algum erro.',
            matchEmail: 'Os e-mails não coincidem',
            searchFailed: 'Não foi encontrada a Pawdiguard associada. Tente mais tarde ou contacte-nos para mais assistência.'
          },
          buttons: {
            call: 'ligar aos tutores',
            num1: 'contacto #1',
            num2: 'contacto #2',
            text: 'enviar sms aos tutores',
            share: 'partilhar localização',
            vet: 'contactar veterinário',
            nearbyVet: 'ver veterinários próximos',
            nearbyShelter: 'ver abrigos próximos'
          },
          id: {
            microTrue: 'com microchip',
            microFalse: 'sem microchip',
            microUnk: 'microchip desconhecido',
            sterTrue: 'esterilizado, ',
            sterFalse: 'não esterelizado, ',
            sterUnk: 'esterilização desconhecida, '
          },
          form: {
            firstTitle: 'ANIMAL',
            secondTitle: 'TUTOR',
            thirdTitle: 'VETERINÁRIO',
            name: 'nome*',
            gender: 'género*',
            male: 'macho',
            female: 'fêmea',
            microchip: 'microchip*',
            sterilized: 'esterilização*',
            yes: 'sim',
            no: 'não',
            other: 'não sei',
            email: 'e-mail*',
            emailnotedit: 'Não é possível editar o e-mail',
            mobile: 'telemóvel*',
            vetName: 'nome',
            phone: 'telefone',
            password: 'password*',
            button: 'salvar info',
            agree: 'Concordo com os',
            tc: 'termos e condições.*',
            emailConf: 'confirmar e-mail*',
            pwConf: 'confirmar password*',
            validEmail: 'e-mail não é válido'
          },
          message: {
            subject: 'PAWDIGUARD - Partilha de localização',
            one: 'Olá\nFoi partilhada a localização do teu animal de estimação nas seguintes coordenadas: ',
            two:' com uma precisão de cerca de ',
            three:' metros.\n Esperamos que esta mensagem seja útil na vossa re-união.\n Pawdiguard'
          },
          missing: {
            firstTitle: 'ÚLTIMO AVISTAMENTO',
            secondTitle: 'INFO RELEVANTE',
            date: 'data',
            place: 'local',
            message: 'mensagem personalizada',
            info1: 'Último avistamento a',
            info2: ' em '
          },
          popup: {
            confirmPass: 'editar dados',
            confirmPassExp: 'insere a password que recebeste quando configuraste a tua pawdiguard para que possas editar os dados',
            confirmEmail: 'Verifica se o teu e-mail está correcto antes de finalizar o registo.',
            confirm: 'confirmar',
            confirmAlert: 'adicionar desaparecimento',
            confirmPassAlert: 'insere a password que recebeste quando configuraste a tua pawdiguard para que possas adicionar o desaparecimento',
            removeAlert: 'cancelar desaparecimento',
            removePassAlert: 'insere a password que recebeste quando configuraste a tua pawdiguard para que possas validar que o teu animal de estimação está junto a ti',
          },
          reunited: {
            firstTitle: 'JÁ ENCONTRASTE O TEU ANIMAL?',
            y: 'sim',
            n: 'não'
          },
          search: {
            vet: 'veterinário',
            shelter: 'canil'
          },
          editFooter: 'editar dados',
          reunitedFooter: 'não enviar mais lembretes',
          alertFooter: `já não está desaparecido`,
          warning: 'ANIMAL DESAPARECIDO! AJUDA-O A VOLTAR PARA A SUA FAMÍLIA!',
          registryTitle:'INFO DO MEU ANIMAL',
          missingTitle:'O MEU ANIMAL DESAPARECEU',
          searchFor: 'Pesquisa o código na parte de trás da Pawdiguard para encontrares mais informação.'      }
      }
    }
  });

export default i18n;