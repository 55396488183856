import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Banner from '../components/banner'
import Id from '../components/id'
import Buttons from '../components/buttons'
import Footer from '../components/footer'
import Warning from '../components/warning'
import AlertInfo from '../components/alertInfo'
import Loading from '../components/loading'
import Title from '../components/title'
import Form from '../components/form'

export default function PetForm() {
  
  const [ data, setData ] = useState({});
  const petId = useParams();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND}/${petId.petId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json() )
      .then(data => {
        setData(data.data[0]);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  const status = data.statusMissing;
  const condition = data.statusPaw;
  
  while ( loading ) return <Loading/>;

  if (condition === "new") {
    return (
      <div className = 'bg-regular-bg flex flex-col min-h-screen w-full mx-auto justify-between'>
        <header><Banner data = {data}/></header>
        <main className='flex-grow'>
          <Title/>
          <Form/>
        </main>
      </div>
    )
  }

  return (
    <div className = {`${status ? 'bg-alert-bg' : 'bg-regular-bg'} flex flex-col min-h-screen w-full mx-auto justify-between`}>
        <header><Banner data = {data}/></header>
        <main className='flex-grow'>
          { status ? <Warning/> : '' }
          <Id data = {data} />
          { status ? <AlertInfo/> : '' }
          <Buttons data = {data}/>
        </main>
        <footer className='h-12 py-4 right-auto'><Footer data = {data}/></footer>
    </div>
  );
}