import React from 'react';
import { useTranslation } from 'react-i18next';

const lngs = {
  pt: { nativeName: 'Português' },
  en: { nativeName: 'English' }
};

export default function Banner(props) {
  const { i18n } = useTranslation();

  const petInfo = props.data;
  let status = petInfo.statusMissing;

  const current = i18n.languages[0];

  return (
      // <div className="basis-full py-8">
      //     <h3 className={`${statusMissing ? 'text-alert-ac' : 'text-regular-ac'} text-sm font-medium text-center`}>PAWDIGUARD</h3>
      // </div>
      <div className="flex flex-col w-[300px] mx-auto py-4">
        <div className="grid">
          <h3 className={`${status || window.location.href.includes('missing') ? 'text-alert-ac' : status === undefined ? 'text-regular-bg' :'text-regular-ac'} text-sm font-medium text-center`}><a href='/'>PAWDIGUARD</a></h3>
        </div>
        <div className="grid">
          <div className='space-x-3 text-right'>
            {Object.keys(lngs).map( lng => 
              <a className={`${current === lng ? 'font-extrabold' : 'font-medium'} ${status || window.location.href.includes('missing') ? 'text-alert-ac' : status === undefined ? 'text-regular-bg' :'text-regular-ac'}`} key={lng} onClick={() => { i18n.changeLanguage(lng) }}>{lng}</a>
            )}
          </div>
        </div>
      </div>
  );
}