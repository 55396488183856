import React, { useEffect, useState }from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import moment from 'moment'

export default function AlertInfo() {
  const { t, i18n } = useTranslation();

  const petId = useParams();
  const [ data, setData ] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND}/${petId.petId}/missingdata`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json() )
      .then(data => {
        setData(data.data[0]);
      })
  }, []);

  const location = data.lastLocation;
  const date = data.lastDate;
  const message = data.message;

  return (
      <div className="flex flex-col w-[300px] mx-auto">
        <div className="grid">
          <div className='font-bold text-xl text-alert-ac'>
            <p>{t('missing.info1')} {moment(date).format("DD-MM-YYYY")} {t('missing.info2')} {location}</p>
          </div>
          <div className='mt-4 w-[300px] bg-regular-bg rounded-[10px] shadow-md'>
            <p className='p-4 font-medium text-alert-ac text-base'>{message}</p>
          </div>
        </div>
      </div>
  );
}