import React, { useState }  from 'react';
import { useTranslation } from 'react-i18next';

export default function Footer(props) {
  const { t, i18n } = useTranslation();

  const petInfo = props.data;
  const petId = petInfo._id;

  const status = petInfo.statusMissing;

  const reminder = window.location.href.includes('reunited');

  if (status) {
    // if (reminder) {
      return (
        <div className="basis-full grid place-items-center">
          <a href={`/${petId}/reunited`} className={`${status ? 'text-alert-ac' : 'text-regular-ac'} text-base font-bold underline text-center`}>{t('reunitedFooter')}</a>
        </div>
        );
    // } else {
    //   return (
    //     <div className="basis-full grid place-items-center">
    //       <a href={`/${petId}/reunited`} className={`${status ? 'text-alert-ac' : 'text-regular-ac'} text-base font-bold underline text-center`}>{t('alertFooter')}</a>
    //     </div>
    //     );
    // }
  } else {
      return (
        <div className="basis-full grid place-items-center">
            <a href={`/${petId}/edit`} className={`${status || window.location.href.includes('missing') ? 'text-alert-ac' : 'text-regular-ac'} text-base font-bold underline text-center`}>{t('editFooter')}</a>
        </div>
      );
  }


}