import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdFemale, IoMdMale } from 'react-icons/io';

// const lngs = {
//   pt: { nativeName: 'Português' },
//   en: { nativeName: 'English' }
// };

export default function Id(props) {
  const { t, i18n } = useTranslation();

  const petInfo = props.data;

  const status = petInfo.statusMissing;
  const petData = petInfo.pet;

  return (
      <div className="flex flex-col w-[300px] mx-auto py-4">
        <div className="grid grid-cols-2">
          <div className={`font-bold text-4xl ${status ? 'text-alert-ac' : 'text-regular-ac'}`}>
            <p>{petData.petName}</p>
          </div>
          <div className='space-x-3 text-right'>
            {/* {Object.keys(lngs).map((lng) => (
              <a className={`font-medium ${status ? 'text-alert-ac' : 'text-regular-ac'} active:font-extrabold`} onClick={() => i18n.changeLanguage(lng)}>{lng}</a>
            ))} */}
          </div>
        </div>
        <div className="grid grid-cols-8 align-baseline">
          <div className={`font-bold text-4xl ${status ? 'text-alert-ac' : 'text-regular-ac'}`}>
            { petData.petGender === 'female' ? (
              <p><IoMdFemale /></p>
            ) : (
              <p><IoMdMale /></p>
            )}
          </div>
          <div className={`font-bold text-sm ${status ? 'text-alert-ac' : 'text-regular-ac'} col-span-7 pt-4`}>
          { petData.petSter === 'true' ? (
              <span>{t('id.sterTrue')}</span>
            ) : petData.petSter === 'false' ? (
              <span>{t('id.sterFalse')}</span>
            ):
            <span>{t('id.sterUnk')}</span>
            }
            { petData.microchip === 'true' ? (
              <span>{t('id.microTrue')}</span>
            ) : petData.microchip === 'false' ? (
              <span>{t('id.microFalse')}</span>
            ) :
            <span>{t('id.microUnk')}</span>
            }
          </div>
        </div>
      </div>
  );
}