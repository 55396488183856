import React from 'react';

export default function TC() {
  return (
    <div className = 'bg-regular-bg flex flex-col min-h-screen w-full mx-auto justify-between'>
        <main className='flex-grow m-10 font-medium text-xs text-regular-ac'>
          <h3 className='font-bold'>PAWDIGUARD'S TERMS AND CONDITIONS</h3>
          <br></br>
          <p>Effective Date: 19/08/2023</p>
          <br></br>
          <p>These Terms and Conditions ("Agreement") govern the use of the Pawdiguard NFC Pet Tag ("Product") and the associated services provided by Pawdiguard ("Company," "we," "us," or "our"). By using the Pawdiguard NFC Pet Tag and submitting your contact information, you ("User," "Owner," or "you") agree to comply with and be bound by this Agreement. If you do not agree with these terms, please do not use the Product.</p>
          <br></br>
          <p><b>1. Product Overview:</b><br></br>The Pawdiguard NFC Pet Tag is designed to provide a contact information solution for lost pets. The tag utilizes Near Field Communication (NFC) technology to allow anyone with an NFC-enabled device to access the contact information you provide for your pet.</p>
          <br></br>
          <p><b>2. User Obligations:</b><br></br>By using the Pawdiguard NFC Pet Tag, you agree to the following:
            <br></br>
            <b>a. Accurate Information:</b> You will provide accurate and up-to-date contact information for yourself as the pet owner.
            <br></br>
            <b>b. Privacy:</b> You understand that the contact information you provide may be accessed by individuals who scan the NFC tag. It is your responsibility to ensure that the information you provide is suitable for public display.
            <br></br>
            <b>c. Liability:</b> You acknowledge that the Company is not responsible for any actions or consequences resulting from the use or misuse of the contact information provided through the Pawdiguard NFC Pet Tag.
          </p>
          <br></br>
          <p><b>3. User Data:</b><br></br>You grant the Company the right to collect, store, and process the contact information you provide for the purpose of operating the Pawdiguard NFC Pet Tag service. We will not use this information for any purpose other than to provide the intended service.</p>
          <br></br>
          <p><b>4. Limitation of Liability:</b><br></br>The Company shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from the use or inability to use the Product.</p>
          <br></br>
          <p><b>5. Changes to the Agreement:</b><br></br>The Company reserves the right to modify or replace these terms at any time. Your continued use of the Product after any changes indicate your acceptance of the modified terms. It is your responsibility to review the terms periodically for changes.</p>
          <br></br>
          <p><b>6. Termination:</b><br></br>The Company reserves the right to terminate or suspend your access to the Pawdiguard NFC Pet Tag service at its sole discretion, without prior notice or liability, for any reason whatsoever.</p>
          <br></br>
          <p><b>7. Governing Law:</b><br></br>This Agreement shall be governed by and construed in accordance with the laws of Portugal, without regard to its conflict of law principles.</p>
          <br></br>
          <p><b>8. Contact Information:</b><br></br>For questions or concerns regarding this Agreement or the Pawdiguard NFC Pet Tag, you can contact us at info@pawdiguard.com.</p>        
          <br></br>
          <p>By using the Pawdiguard NFC Pet Tag, you acknowledge that you have read, understood, and agreed to abide by these terms and conditions. If you do not agree with any part of this Agreement, please refrain from using the Product.</p>
        </main>
    </div>
  );
}