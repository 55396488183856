import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function Search() {
    const { t, i18n } = useTranslation();

    const [pawId, setPawId] = useState("");
    const [search, setSearch] = useState(false);


    const handleSearch = async (e) => {
        e.preventDefault();
        await fetch( `${process.env.REACT_APP_BACKEND}/search`, {
            method: "POST",
            body: JSON.stringify({pawId}),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => { 
            if ( data.status === 'ok' ) {
                setSearch(true)
                
                const url = data.data[0]._id;
                console.log(url)
                window.location.replace(`${process.env.REACT_APP_FRONTEND}/${url}`);
                setSearch(false);
            } else {
                alert(t('alerts.searchFailed'))
            }
        })

      }
    

  return (
      <div className="flex flex-col h-screen px-4">
        <div className="mx-auto mt-32 mb-4 text-regular-bg text-sm">
            <p>{t('searchFor')}</p>
        </div>
      <div className="search-box mx-auto w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4">
          <form className="flex flex-row ">
              <input
                  className="h-12 bg-regular-bg rounded rounded-xl-none text-grey-darker py-2 text-grey-darkest border border-gray-100 mr-2 w-full px-2 outline-none text-lg text-gray-600"
                  type="text" placeholder="PDXXXXXXX" value={pawId} onChange={(e) => setPawId(e.target.value)}></input>
              <span
                  className="flex items-center bg-regular-bg rounded rounded-xl-none border-0 px-3 font-bold text-grey-100">
                  <button className="bg-gradient-dark hover:bg-gradient-light text-lg text-white font-bold py-3 px-2 rounded" onClick={handleSearch}>
                      <svg className="w-4 h-4 text-regular-ac" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                      </svg>
                      </button>
              </span>
          </form>
      </div>
  </div>
  );
}