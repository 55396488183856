import { Routes, Route } from 'react-router-dom';
import React from "react";

import './index.css';    

import PetForm from './pages/petForm';
import Registry from './pages/registry';
import Missing from './pages/missing';
import Reunited from './pages/reunited';
import TC from './pages/TC';
import Start from './pages/start';
import Home from './pages/home';


function App() {  
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/search" element={<Start/>} />
      <Route path="/:petId" element={<PetForm/>} />
      <Route path="/:petId/edit" element={<Registry/>} />
      <Route path="/registry" element={<Registry />} />
      <Route path="/:petId/missing" element={<Missing />} />
      <Route path="/:petId/reunited" element={<Reunited />} />
      <Route path="/tandc" element={<TC />} />
    </Routes>
  );
}

export default App;