import React from 'react';
import { useTranslation } from 'react-i18next';

const statusMissing = true;

export default function Warning() {
  const { t, i18n } = useTranslation();

  return (
      <div className="flex flex-col w-[300px] mx-auto">
        <div className='font-extrabold text-4xl text-alert-ac underline'>
          <p>{t('warning')}</p>
        </div>
      </div>
  );
}