import React from 'react';
import { useTranslation } from 'react-i18next';
import { sendEmail } from '../Utils.js';

export default function Buttons(props) {
  const { t } = useTranslation();

  const petInfo = props.data;

  const status = petInfo.statusMissing;
  const phoneNumber1 = petInfo.owner.ownerPhone1;
  // const phoneNumber2 = petInfo.owner.ownerPhone2;
  const vetNumber = petInfo.vet.vetPhone;

  // const [phoneForCall, setPhoneForCall] = useState();
  // const [phoneForText, setPhoneForText] = useState();

  function success(pos) {
    let crd = pos.coords;
    //console.log("Your current position is:");
    //console.log(`Latitude : ${crd.latitude}`);
    //console.log(`Longitude: ${crd.longitude}`);
    //console.log(`More or less ${crd.accuracy} meters.`);

    const message = `Hi \nThe location of ${petInfo.pet.petName} was shared with the following coordinates: ${crd.latitude}, ${crd.longitude} with an accuracy of about ${crd.accuracy.toFixed()} meters.\nWe hope this message promotes your reunion.\nPawdiguard `
    const subject = 'PAWDIGUARD - Shared location';

    const email = {
      subj: subject,
      receiver: petInfo.owner.ownerEmail,
      mess: message
    }

    sendEmail(email);
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const getLocation = () => {
    if (navigator.geolocation) {
        navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
            console.log(result);
            if (result.state === "granted") {
              navigator.geolocation.getCurrentPosition(success, errors, options);
              alert(t('alerts.locationGranted'))
            } else if (result.state === "prompt") {
              navigator.geolocation.getCurrentPosition(success, errors, options);
              alert(t('alerts.locationGranted'))
            } else if (result.state === "denied") {
              alert(t('alerts.locationDenied'))
            }
        });
    } else {
        console.log("Geolocation is not supported by this browser.");
    }
  };

  let buttons = [
    {id: 0, value: 'buttons.call', link: 'tel:'+ phoneNumber1},
    {id: 1, value: 'buttons.text', link: 'sms:'+ phoneNumber1 },
    {id: 2, value: 'buttons.share', link: ''},
    {id: 3, value: 'buttons.vet', link: 'tel:'+ vetNumber},
    {id: 4, value: 'buttons.nearbyVet', link: 'https://www.google.com/maps/search/vet'},
    {id: 5, value: 'buttons.nearbyShelter', link: 'https://www.google.com/maps/search/kennel'}
  ];

  // let buttons = [
  //   [
  //     {id: 0, value: 'buttons.num1', link: 'tel:+351'+ phoneNumber1},
  //     {id: 1, value: 'buttons.num2', link: 'tel:+351'+ phoneNumber2 }
  //   ],
  //   [
  //     {id: 0, value: 'buttons.num1', link: 'sms:+351'+ phoneNumber1},
  //     {id: 1, value: 'buttons.num2', link: 'sms:+351'+ phoneNumber2 }
  //   ],
  //   {id: 0, value: 'buttons.call', link: 'tel:+351'+ phoneNumber1},
  //   {id: 1, value: 'buttons.text', link: 'sms:+351'+ phoneNumber1 },
  //   {id: 2, value: 'buttons.share', link: ''},
  //   {id: 3, value: 'buttons.vet', link: 'tel:+351'+ vetNumber},
  //   {id: 4, value: 'buttons.nearbyVet', link: 'https://www.google.com/maps/search/vet'},
  //   {id: 5, value: 'buttons.nearbyShelter', link: 'https://www.google.com/maps/search/kennel'}
  // ];

  // let callNum = [
  //   {id: 0, value: 'buttons.num1', link: 'tel:+351'+ phoneNumber1},
  //   {id: 1, value: 'buttons.num2', link: 'tel:+351'+ phoneNumber2 },
  // ];

  // let textNum = [
  //   {id: 0, value: 'buttons.num1', link: 'sms:+351'+ phoneNumber1},
  //   {id: 1, value: 'buttons.num2', link: 'sms:+351'+ phoneNumber2 },
  // ];

  // if (phoneNumber2 === '') {
  //   buttons.splice(1,1);
  //   buttons.splice(3,1);
  // };

  if (vetNumber === '') {
    buttons.splice(3,1);
  };

  // const [open, setOpen] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(!open);
  // };

  // const callPhone = () => {
  //   if (phoneForCall === "1") {
  //     Linking.openURL(`tel:${phoneNumber1}`)
  //   } else if (phoneForCall === "2") {
  //     Linking.openURL(`tel:${phoneNumber2}`)
  //   }
  // }

  // if (phoneNumber2.trim()) {
  //   return(
  //     <div className="grid gap-7 place-items-center mt-4">           
  //       <select onClick={callPhone} value={phoneForCall} onChange={(e) => setPhoneForCall(e.target.value)} className={`form-select ${status ? 'bg-alert-ac' : 'bg-regular-ac'} text-regular-bg text-lg font-medium rounded-[10px] h-[50px] w-[300px] shadow-lg text-center align-middle`}>
  //         <option defaultValue>{t('buttons.call')}</option>
  //         <option value = "1">A</option>
  //         <option value = "2">B</option>
  //       </select>   

  //     </div>
  //   )
  // } else {
    return (
      <div className="grid gap-7 place-items-center mt-4">           
        {buttons.map( b =>
          <a className='text-regular-bg text-lg font-medium' href={b.link} key={b.id} onClick={(e) => {  if (b.link === '') { e.preventDefault(); getLocation(); }  return false;}}>
            <button className={`${status ? 'bg-alert-ac' : 'bg-regular-ac'} rounded-[10px] h-[50px] w-[300px] shadow-lg text-center align-middle`} type="button" >
              {t(b.value)}
            </button>
          </a>
        )}
      </div>
    );
  }
// }